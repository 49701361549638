@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html, body {
        margin: 0;
        padding: 0;
        background-color: theme('colors.dark-2');
        font-family: 'Montserrat', serif;
        color: theme('colors.light-3');
    }
}

@font-face {
    font-family: 'Montserrat';
    src: url('/public/Montserrat-Regular.ttf');
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}
